/* eslint-disable no-nested-ternary */
import { Collapse, Menu, MenuItem } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { OpenIDContext } from '../../api/auth';
import { CompanyDashboardContext, useCompany, useCompanyPlayersActivities, useCompanyPlayersActivitiesBarChart, useCompanyPlayersActivitiesGrade, useCompanyPlayersActivitiesGradeInternal, useCompanyPlayersActivitiesPieChart } from "../../api/hooks";
import playBackendRequestHandler from '../../api/playBackendRequestHandler';
import { Department, TimePeriod } from '../../enums';
import { clearSelectedVulnerabilities } from '../../redux/vulnerabilitiesSlice';
import ErrorPage from '../../shared/components/ErrorPage/ErrorPage';
import Loader from '../../shared/components/Loader/Loader';
import SecDimButton from '../../shared/components/SecDimButton/SecDimButton';
import { getStartDateAndEndDateBasedOnTimePeriod } from '../../shared/helpers/utils';
import theme from '../../utils/theme';
import * as Styled from './CompanyDashboardStyles';
import CompanyDashboardInfo from './components/CompanyDashboardInfo/CompanyDashboardInfo';
import CompanyPlayerActivityDialog from './components/CompanyPlayerActivityDialog/CompanyPlayerActivityDialog';
import CompanyPlayersActivitiesBarChart from "./components/CompanyPlayersActivitiesBarChart/CompanyPlayersActivitiesBarChart";
import CompanyPlayersActivitiesFilter from './components/CompanyPlayersActivitiesFilter/CompanyPlayersActivitiesFilter';
import CompanyPlayersActivitiesPieChart from './components/CompanyPlayersActivitiesPieChart/CompanyPlayersActivitiesPieChart';
import CompanyPlayersActivitiesTable from "./components/CompanyPlayersActivitiesTable/CompanyPlayersActivitiesTable";
import CompanyPlayersDepartmentFilter from './components/CompanyPlayersDepartmentFilter/CompanyPlayersDepartmentFilter';
import CreateDepartmentDialog from './components/CreateDepartmentDialog/CreateDepartmentDialog';

function CompanyDashboard() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fetchCompanyData = useCompany()
    const fetchCompanyPlayersActivitiesData = useCompanyPlayersActivities()
    const fetchCompanyPlayersActivitiesBarChartData = useCompanyPlayersActivitiesBarChart()
    const fetchCompanyPlayersActivitiesPieChartData = useCompanyPlayersActivitiesPieChart()
    const fetchCompanyComparativeGradeData = useCompanyPlayersActivitiesGrade()
    const fetchCompanyComparativeGradeDataInternal = useCompanyPlayersActivitiesGradeInternal()
    const [companyData, setCompanyData] = useState<Company>()
    const [companyPlayersActivitiesData, setCompanyPlayersActivitiesData] = useState<CompanyPlayersActivities>({ number_of_subscribed_players: 0, player_challenge_attempts: [] })
    const [companyPlayersActivitiesBarChartData, setCompanyPlayersActivitiesBarChartData] = useState<CompanyPlayersActivitiesBarChart[]>([])
    const [companyPlayersActivitiesPieChartData, setCompanyPlayersActivitiesPieChartData] = useState<CompanyPlayersActivitiesPieChart[]>([])
    const [companyComparativeGradeData, setCompanyComparativeGradeData] = useState<CompanyComparativeGrade>()
    const [companyComparativeGradeDataInternal, setCompanyComparativeGradeDataInternal] = useState<DepartmentComparativeGrade[]>([])
    const [openCompanyPlayerProfileDialogInfo, setOpenCompanyPlayerProfileDialogInfo] = useState<CompanyPlayerActivityInfo>({ isDialogShown: false, playerUsername: "", playerEmail: "", playerGuid: "" })
    const [isCreateDepartmentDialogOpened, setIsCreateDepartmentDialogOpened] = useState(false)
    const [selectedDepartment, setSelectedDepartment] = useState(String(Department.Default))
    const [addMenuOpen, setAddMenuOpen] = useState<null | HTMLElement>(null);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState(String(TimePeriod.Default));
    const [permissionAlert, setPermissionAlert] = useState<string>('')
    const [permissionAlertOpen, setPermissionAlertOpen] = useState(false)
    const { profile } = useContext(OpenIDContext)

    // the following useEffect is for initializing companyData state following the Company api response
    useEffect(() => {
        if (fetchCompanyData?.data?.data) {
            setCompanyData(fetchCompanyData?.data?.data)
        }
    }, [fetchCompanyData?.data])

    // the following useEffect is for initializing the attempts table based on CompanyPlayersActivities api response
    useEffect(() => {
        if (fetchCompanyPlayersActivitiesData?.data?.data) {
            setCompanyPlayersActivitiesData(fetchCompanyPlayersActivitiesData?.data.data)
        }
    }, [fetchCompanyPlayersActivitiesData?.data])

    useEffect(() => {
        if (fetchCompanyComparativeGradeData?.data?.data) {
            setCompanyComparativeGradeData(fetchCompanyComparativeGradeData?.data.data)
        }
    }, [fetchCompanyComparativeGradeData?.data])

    useEffect(() => {
        if (fetchCompanyComparativeGradeDataInternal?.data?.data) {
            setCompanyComparativeGradeDataInternal(fetchCompanyComparativeGradeDataInternal?.data.data)
        }
    }, [fetchCompanyComparativeGradeDataInternal?.data])

    // the following useEffect is for initializing the bar chart based on CompanyPlayersActivitiesBarChart api response
    useEffect(() => {
        if (fetchCompanyPlayersActivitiesBarChartData?.data?.data) {
            const updatedData = fetchCompanyPlayersActivitiesBarChartData?.data?.data.map((stat) => {
                const matchedPlayer = companyData?.players?.find((player: any) => player.username === stat.player);
                return {
                    ...stat,
                    department: matchedPlayer ? matchedPlayer.department?.name : '-',
                };
            });
            setCompanyPlayersActivitiesBarChartData(updatedData)
        }
    }, [companyData?.players, fetchCompanyPlayersActivitiesBarChartData?.data])

    // the following useEffect is for initializing the pie chart based on CompanyPlayersActivitiesBarChart api response
    useEffect(() => {
        if (fetchCompanyPlayersActivitiesPieChartData?.data?.data) {
            const updatedData = fetchCompanyPlayersActivitiesPieChartData?.data?.data.map((stat) => {
                const matchedPlayer = companyData?.players?.find((player: any) => player.username === stat.player_username);
                return {
                    ...stat,
                    department: matchedPlayer ? matchedPlayer.department?.name : '-',
                };
            });
            setCompanyPlayersActivitiesPieChartData(updatedData)
        }
    }, [companyData, fetchCompanyPlayersActivitiesPieChartData?.data])

    // make api call with start date and end date and set the response to the attempts table
    const makeCompanyPlayersActivitiesAPICall = (getStartAndEndDate: any) => {
        playBackendRequestHandler(
            'companyPlayersActivities',
            undefined,
            undefined,
            getStartAndEndDate ? `?start_date=${getStartAndEndDate.startDate}&end_date=${getStartAndEndDate.endDate}` : undefined
        ).then((resp: any) => {
            if (resp.status === 200) {
                setCompanyPlayersActivitiesData(resp.data)
            }
        })
    }

    // make api call with start date and end date and set the response to the map
    const makeCompanyPlayersActivitiesBarChartAPICall = useCallback((getStartAndEndDate: any) => {
        playBackendRequestHandler(
            'companyPlayersActivitiesBarChart',
            undefined,
            undefined,
            getStartAndEndDate ? `?start_date=${getStartAndEndDate.startDate}&end_date=${getStartAndEndDate.endDate}` : undefined
        ).then((resp: any) => {
            if (resp.status === 200) {
                const updatedData = resp?.data?.map((stat: any) => {
                    const matchedPlayer = companyData?.players?.find((player: any) => player.username === stat.player);
                    return {
                        ...stat,
                        department: matchedPlayer ? matchedPlayer.department?.name : '-',
                    };
                });
                setCompanyPlayersActivitiesBarChartData(updatedData)
            }
        })
    }, [companyData?.players])

    const makeCompanyPlayersActivitiesPieChartAPICall = (getStartAndEndDate: any) => {
        playBackendRequestHandler(
            'companyPlayersActivitiesPieChart',
            undefined,
            undefined,
            getStartAndEndDate ? `?start_date=${getStartAndEndDate.startDate}&end_date=${getStartAndEndDate.endDate}` : undefined
        ).then((resp: any) => {
            if (resp.status === 200) {
                setCompanyPlayersActivitiesPieChartData(resp.data)
            }
        })
    }

    const makeCompanyGradeAPICall = (getStartAndEndDate: any) => {
        playBackendRequestHandler(
            'companyPlayersActivitiesGrade',
            undefined,
            undefined,
            getStartAndEndDate ? `?start_date=${getStartAndEndDate.startDate}&end_date=${getStartAndEndDate.endDate}` : undefined
        ).then((resp: any) => {
            if (resp.status === 200) {
                setCompanyComparativeGradeData(resp.data)
            }
        })
    }

    const makeCompanyGradeInternalAPICall = (getStartAndEndDate: any) => {
        playBackendRequestHandler(
            'companyPlayersActivitiesGradeInternal',
            undefined,
            undefined,
            getStartAndEndDate ? `?start_date=${getStartAndEndDate.startDate}&end_date=${getStartAndEndDate.endDate}` : undefined
        ).then((resp: any) => {
            if (resp.status === 200) {
                setCompanyComparativeGradeDataInternal(resp.data)
            }
        })
    }

    const refetchUpdatedCompanyData = useCallback(() => {
        let getStartAndEndDate = null
        if (TimePeriod.Default !== selectedTimePeriod) {
            getStartAndEndDate = getStartDateAndEndDateBasedOnTimePeriod(selectedTimePeriod)
            makeCompanyGradeAPICall(getStartAndEndDate)
            makeCompanyGradeInternalAPICall(getStartAndEndDate)
        } else {
            makeCompanyGradeAPICall(null)
            makeCompanyGradeInternalAPICall(null)
        }
        makeCompanyPlayersActivitiesAPICall(getStartAndEndDate)
        makeCompanyPlayersActivitiesBarChartAPICall(getStartAndEndDate)
        makeCompanyPlayersActivitiesPieChartAPICall(getStartAndEndDate)
    }, [makeCompanyPlayersActivitiesBarChartAPICall, selectedTimePeriod])

    useEffect(() => {
        refetchUpdatedCompanyData()
    }, [refetchUpdatedCompanyData, selectedTimePeriod])

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAddMenuOpen(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAddMenuOpen(null);
    };

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>
        {
            fetchCompanyPlayersActivitiesData.isLoading || fetchCompanyPlayersActivitiesBarChartData.isLoading || fetchCompanyPlayersActivitiesPieChartData.isLoading || fetchCompanyComparativeGradeData.isLoading || fetchCompanyComparativeGradeDataInternal.isLoading ?
                <Loader /> :
                fetchCompanyPlayersActivitiesData.isError || !fetchCompanyPlayersActivitiesData.isSuccess || fetchCompanyPlayersActivitiesBarChartData.isError || !fetchCompanyPlayersActivitiesBarChartData.isSuccess || fetchCompanyPlayersActivitiesPieChartData.isError || !fetchCompanyPlayersActivitiesPieChartData.isSuccess ?
                    <ErrorPage
                        errorIconName='Block'
                        errorTitle='Company Dashboard'
                        errorMessage={fetchCompanyPlayersActivitiesData.error ? (fetchCompanyPlayersActivitiesData.error as any).response.data.detail : fetchCompanyPlayersActivitiesBarChartData.error ? (fetchCompanyPlayersActivitiesBarChartData.error as any).response.data.detail : fetchCompanyPlayersActivitiesPieChartData.error ? (fetchCompanyPlayersActivitiesPieChartData.error as any).response.data.detail : ""}
                    /> :
                    <>
                        <Collapse in={permissionAlertOpen}>
                            <Styled.CustomAlert
                                onClick={() => setPermissionAlertOpen(false)}
                                severity="error"
                                action={<Styled.CustomCloseIcon fontSize="inherit" />}
                            >
                                {permissionAlert}
                            </Styled.CustomAlert>
                        </Collapse>
                        <CreateDepartmentDialog
                            isOpenDialog={isCreateDepartmentDialogOpened}
                            handleCloseDialog={() => {
                                setIsCreateDepartmentDialogOpened(!isCreateDepartmentDialogOpened)
                            }}
                            refetchCompanyData={fetchCompanyData}
                        />
                        <CompanyDashboardContext.Provider
                            // eslint-disable-next-line react/jsx-no-constructed-context-values
                            value={{
                                openCompanyPlayerProfileDialogInfo,
                                setOpenCompanyPlayerProfileDialogInfo,
                            }}>
                            {openCompanyPlayerProfileDialogInfo.isDialogShown && <CompanyPlayerActivityDialog companyPlayersActivitiesPieChartData={companyPlayersActivitiesPieChartData} departments={companyData!.departments!} refetchCompanyData={fetchCompanyData} />}
                            <Styled.DashboardHeaderAndFilterWrapper>
                                <Styled.Title variant='h1'>{companyComparativeGradeData?.company_name}</Styled.Title>
                                <Styled.DashboardHeaderButtonAndFilterWrapper>
                                    <SecDimButton rounded variant="outlined"
                                        sx={{
                                            backgroundColor: theme.palette.common.white,
                                            color: theme.palette.primary.main,
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.common.white,
                                            }
                                        }}
                                        onClick={handleMenuOpen}
                                    ><Styled.CustomAddIcon />Add</SecDimButton>
                                    <Menu
                                        anchorEl={addMenuOpen}
                                        keepMounted
                                        open={Boolean(addMenuOpen)}
                                        onClose={handleMenuClose}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem onClick={() => {
                                            handleMenuClose();
                                        }}>
                                            <Styled.UserNavLink
                                                onClick={() => {
                                                    if (profile.restrictions.hasAddCompanyGamePermission) {
                                                        dispatch(clearSelectedVulnerabilities())
                                                        navigate('/create/game')
                                                    } else {
                                                        setPermissionAlertOpen(true)
                                                        setPermissionAlert("Upgrade your account to manage company games.")
                                                    }
                                                }}
                                            >
                                                <Styled.CustomGamepad />{' '}
                                                <Styled.UserNavLinkText>Game</Styled.UserNavLinkText>
                                            </Styled.UserNavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            handleMenuClose();
                                        }}>
                                            <Styled.UserNavLink
                                                onClick={() => {
                                                    if (profile.restrictions.hasAddCompanyDepartmentPermission) {
                                                        setIsCreateDepartmentDialogOpened(!isCreateDepartmentDialogOpened)
                                                    } else {
                                                        setPermissionAlertOpen(true)
                                                        setPermissionAlert("Upgrade your account to manage company departments.")
                                                    }
                                                }}
                                            >
                                                <Styled.CustomBusiness />{' '}
                                                <Styled.UserNavLinkText>Department</Styled.UserNavLinkText>
                                            </Styled.UserNavLink>
                                        </MenuItem>
                                    </Menu>
                                    <CompanyPlayersDepartmentFilter company={companyData!} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} />
                                    <CompanyPlayersActivitiesFilter selectedTimePeriod={selectedTimePeriod} setSelectedTimePeriod={setSelectedTimePeriod} />
                                </Styled.DashboardHeaderButtonAndFilterWrapper>
                            </Styled.DashboardHeaderAndFilterWrapper>
                            <CompanyDashboardInfo companyPlayersActivitiesData={companyPlayersActivitiesData} companyComparativeGradeData={companyComparativeGradeData!} companyComparativeGradeDataInternal={companyComparativeGradeDataInternal} department={selectedDepartment} />
                            <Styled.GraphTableContainer container>
                                <Styled.Table item xs={12} sm={12} marginTop={5}>
                                    <CompanyPlayersActivitiesTable company={companyData!} companyPlayersActivitiesData={companyPlayersActivitiesData.player_challenge_attempts} department={selectedDepartment} refetchData={refetchUpdatedCompanyData} setPermissionAlert={setPermissionAlert} setPermissionAlertOpen={setPermissionAlertOpen} />
                                </Styled.Table>
                                <Styled.Graph container spacing={2}>
                                    <Styled.Graph item xs={12} sm={6}>
                                        <Styled.PieChartBox>
                                            <CompanyPlayersActivitiesPieChart companyPlayersActivitiesPieChartData={companyPlayersActivitiesPieChartData} department={selectedDepartment} />
                                        </Styled.PieChartBox>
                                    </Styled.Graph>
                                    <Styled.Graph item xs={12} sm={6}>
                                        <CompanyPlayersActivitiesBarChart companyPlayersActivitiesBarChartData={companyPlayersActivitiesBarChartData} department={selectedDepartment} />
                                    </Styled.Graph>
                                </Styled.Graph>
                            </Styled.GraphTableContainer >
                        </CompanyDashboardContext.Provider>
                    </>
        }
    </>
}


export default CompanyDashboard
